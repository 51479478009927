export const APP_ROUTES = {
  Landing: "/",
  Dashboard: "/dashboard",
  Login: "/login",
  Register: "/register",
  Any: "/*",
  VerifyEmail: "verify-email",
  Privacy: "/privacy-policy",
  Term: "/terms-and-conditions",
  // OtpVerification: "/otp-verification",
  ContactUs: "/contact-us",
  Forgot: "/forgot-password",

  Home: "/home",
  GameList: "/game-list",

  SettingPage: "/profile-page",
  Profile: "/profile-page/update-profile",
  Password: "/profile-page/update-password",
  History: "/profile-page/user-history",

  Bank: "/profile-page/bank-data",
  Admin: "/admin",
  AdminRequest: "/admin/request",
};
export const API_ROUTES = {
  RefreshToken: "/admin/auth/refresh-token",
  Register: "/admin/auth/register",
  Login: "/admin/auth/login",
  LoginWithOtp: "/admin/auth/send_login_otp",
  VerifyOtp: "/admin/auth/login_with_otp",
  VerifyNumber: "/admin/auth/verify-mobile-number",

  GetGames: "/admin/games/list",
  AddBid: "/admin/bidapplication/create",
  GetBid: "/admin/bidapplication/:id",
  GetInterval: "/admin/timeinterval/get-interval",
  GetUserDetails: "/admin/user/me",
  UpdateUser: "/admin/user/update",
  ChangePassword: "/admin/user/change-password",
  ChangePasswordAdmin: "/admin/user/change-password-admin",

  GetHistory: "/admin/transactions/list",
  StartNewGame: "/admin/winner/checkOutView",
  GetWallet: "/admin/wallet",
  AddBankDetails: "/admin/transactions/addBeneficiary",

  DepositeMoney: "/admin/transactions/getSessionId",
  UserList: "/admin/user/list",
  RequestedUserList: "/admin/transactions/listOfApprovalRequest",

  AddTranscation: "/admin/transactions/create",
  AddTranscationAdmin: "/admin/transactions/createdByAdmin",
  GetUserHistory: "/admin/transactions/listOfAdmin",
  DeleteTranscation: "/admin/transactions/delete/:id",
  UpdateTranscation: "/admin/transactions/update/:id",
  HandleRequest: "/admin/transactions/handleApprovalRequest",
};
