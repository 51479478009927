import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import HttpService from "../../../hooks/Https-services";
import { API_ROUTES, APP_ROUTES } from "../../../utils/constants";
import { useNavigate } from "react-router-dom";

const PopReset = (props: any) => {
  const { id } = props;
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data: any) => {
    try {
      if (data.confirmPassword != data.password) {
        toast.error("Password does not match!");
        return;
      }
      setLoading(true);
      const dataBody = {
        userId: id,
        newPassword: data.password,
      };
      const res = await HttpService.post(
        API_ROUTES.ChangePasswordAdmin,
        dataBody
      );
      reset();
      navigate(APP_ROUTES.Home);
      toast.success("Password updated successfully");
      setOpen(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  return (
    <Box>
      <Button variant="contained" color="info" onClick={() => setOpen(true)}>
        Reset Password
      </Button>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        fullWidth
      >
        <Card>
          <CardContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1.5,
                }}
              >
                <Typography variant="body1" sx={{ mt: 2 }} fontWeight={600}>
                  Change Password
                </Typography>
                <TextField
                  label="New Password"
                  type="password"
                  fullWidth
                  {...register("password", {
                    required: "This field is required.",
                    minLength: {
                      value: 8,
                      message: "Password must be at least 8 characters long.",
                    },
                  })}
                  InputProps={{
                    sx: {
                      backgroundColor: "white",
                    },
                  }}
                  error={!!errors.password}
                  helperText={
                    errors.password && (
                      <Typography variant="caption" sx={{ color: "red" }}>
                        {errors.password.message as any}
                      </Typography>
                    )
                  }
                />
                <TextField
                  fullWidth
                  label="Confirm Password"
                  type="password"
                  variant="outlined"
                  size="medium"
                  {...register("confirmPassword", {
                    required: "This field is required.",
                  })}
                  InputProps={{
                    sx: {
                      backgroundColor: "white",
                    },
                  }}
                  error={!!errors.confirmPassword}
                  helperText={
                    errors.confirmPassword && (
                      <Typography variant="caption" sx={{ color: "red" }}>
                        {errors.confirmPassword.message as any}
                      </Typography>
                    )
                  }
                />
                <LoadingButton
                  loading={isLoading}
                  variant="contained"
                  type="submit"
                  size="large"
                  sx={{ marginTop: "auto" }}
                >
                  Save Changes
                </LoadingButton>
              </Box>
            </form>
          </CardContent>
        </Card>
      </Dialog>
    </Box>
  );
};

export default PopReset;
