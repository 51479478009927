import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import HttpService from "../../hooks/Https-services";
import { API_ROUTES, APP_ROUTES } from "../../utils/constants";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";

const BankData = () => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const onSubmit = async (data: any) => {
    try {
      const datas = {
        account_holder_name: data.username,
        bankAccount: data.bankNumber,
        ifsc: data.ifsc,
        address: data.address,
      };
      const res = await HttpService.post(API_ROUTES.AddBankDetails, datas);
      reset();
      toast.success("Account Added successfully");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
          minHeight: "90svh",
        }}
      >
        <Typography variant="body1" sx={{ mt: 2 }} fontWeight={600}>
          Add Bank Details
        </Typography>
        <TextField
          fullWidth
          label="Account Holder Name"
          variant="outlined"
          InputProps={{
            sx: {
              backgroundColor: "white",
            },
          }}
          error={!!errors.username}
          {...register("username", {
            required: "This field is required.",
          })}
          helperText={
            errors.username && (
              <Typography variant="caption" sx={{ color: "red" }}>
                {errors.username.message as any}
              </Typography>
            )
          }
        />
        <TextField
          fullWidth
          label="Account Number"
          variant="outlined"
          InputProps={{
            sx: {
              backgroundColor: "white",
            },
          }}
          error={!!errors.bankNumber}
          {...register("bankNumber", {
            required: "This field is required.",
          })}
          helperText={
            errors.bankNumber && (
              <Typography variant="caption" sx={{ color: "red" }}>
                {errors.bankNumber.message as any}
              </Typography>
            )
          }
        />
        <TextField
          fullWidth
          label="IFSC Code"
          variant="outlined"
          InputProps={{
            sx: {
              backgroundColor: "white",
            },
          }}
          error={!!errors.ifsc}
          {...register("ifsc", {
            required: "This field is required.",
          })}
          helperText={
            errors.ifsc && (
              <Typography variant="caption" sx={{ color: "red" }}>
                {errors.ifsc.message as any}
              </Typography>
            )
          }
        />
        <TextField
          fullWidth
          label="Address"
          variant="outlined"
          InputProps={{
            sx: {
              backgroundColor: "white",
            },
          }}
          error={!!errors.address}
          {...register("address", {
            required: "This field is required.",
          })}
          helperText={
            errors.address && (
              <Typography variant="caption" sx={{ color: "red" }}>
                {errors.address.message as any}
              </Typography>
            )
          }
        />
        <LoadingButton
          loading={isLoading}
          variant="contained"
          type="submit"
          size="large"
          sx={{ marginTop: "auto" }}
        >
          Add Account
        </LoadingButton>
      </Box>
    </form>
  );
};

export default BankData;
