import {
  Box,
  Button,
  FormControl,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import PhoneInput from "react-phone-number-input";
import HttpService from "../../hooks/Https-services";
import { API_ROUTES, APP_ROUTES } from "../../utils/constants";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import commonDetailSlice from "../../store/reducers/commonReducer";

const Profile = () => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  async function getEntries(data: any) {
    delete data.tokens;
    dispatch(commonDetailSlice.actions.removecommonDetails());
    dispatch(commonDetailSlice.actions.setcommonDetails(data));
  }
  const handleChangePhone = (value: any) => {
    setPhoneNumber(value);
  };
  const getProfile = async () => {
    try {
      const res = await HttpService.get(API_ROUTES.GetUserDetails);
      setValue("username", res.data.data.name);
      setValue("email", res.data.data.email);
      setPhoneNumber(res.data.data.phoneNo);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    getProfile();
  }, []);

  const onSubmit = async (data: any) => {
    try {
      setIsLoading2(true);
      const dataBody = {
        email: data.email,
        name: data.username,
      };
      const res = await HttpService.put(API_ROUTES.UpdateUser, dataBody);
      toast.success("Profile updated successfully");
      getEntries(res.data.data[0]);
      navigate(APP_ROUTES.SettingPage);
      setIsLoading2(false);
    } catch (error) {
      setIsLoading2(false);
      console.log(error);
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          minHeight: "90svh",
        }}
      >
        {!isLoading ? (
          <>
            <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
              <Box sx={{ mb: 1 }}>
                <label htmlFor="number">Phone Number</label>
              </Box>
              <Box
                sx={{
                  border: "1px solid lightgray",
                  borderRadius: "4px",
                  px: "14px",
                  "&:hover": {
                    outline: "0.8px solid black",
                  },
                  "&:focus-within": {
                    outline: "1.9px solid #436af3 !important",
                  },
                }}
              >
                <PhoneInput
                  value={phoneNumber}
                  international
                  autoFormat={false}
                  defaultCountry="IN"
                  country="US"
                  onChange={handleChangePhone}
                  countries={["IN"]}
                  disabled
                />
              </Box>
            </FormControl>
            <TextField
              fullWidth
              label="Username"
              variant="outlined"
              InputProps={{
                sx: {
                  backgroundColor: "white",
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              error={!!errors.username}
              {...register("username", {
                required: "This field is required.",
              })}
              helperText={
                errors.username && (
                  <Typography variant="caption" sx={{ color: "red" }}>
                    {errors.username.message as any}
                  </Typography>
                )
              }
            />
            <TextField
              label="Email"
              type="email"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                sx: {
                  backgroundColor: "white",
                },
              }}
              {...register("email", {
                required: "This field is required.",
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: "Please enter valid email.",
                },
              })}
              error={!!errors.email}
              helperText={
                errors.email && (
                  <Typography variant="caption" sx={{ color: "red" }}>
                    {errors.email.message as any}
                  </Typography>
                )
              }
            />
            <LoadingButton
              loading={isLoading2}
              variant="contained"
              type="submit"
              size="large"
              sx={{ marginTop: "auto" }}
            >
              Save Changes
            </LoadingButton>
          </>
        ) : (
          <>
            <Skeleton
              variant="rounded"
              sx={{ fontSize: "3.5rem", marginTop: 3 }}
            />
            <Skeleton variant="rounded" sx={{ fontSize: "3.5rem" }} />
            <Skeleton variant="rounded" sx={{ fontSize: "3.5rem" }} />
          </>
        )}
      </Box>
    </form>
  );
};

export default Profile;
