import {
  Box,
  Button,
  List,
  ListItemText,
  MenuItem,
  MenuList,
} from "@mui/material";
import React from "react";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../utils/constants";
import { toast } from "react-toastify";

const SettingPage = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("auth");
    toast.success("Logout Successfully");
    navigate(APP_ROUTES.Landing);
  };
  return (
    <Box
      sx={{
        minHeight: "90svh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <List sx={{ fontWeight: 500, my: 1 }}>Account</List>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
        <MenuItem
          sx={{ backgroundColor: "white", borderRadius: "4px" }}
          onClick={() => navigate(APP_ROUTES.Profile)}
        >
          <PersonOutlineOutlinedIcon />
          <ListItemText sx={{ ml: 1 }}>Profile</ListItemText>
          <ChevronRightOutlinedIcon />
        </MenuItem>

        <MenuItem
          sx={{ backgroundColor: "white", borderRadius: "4px" }}
          onClick={() => navigate(APP_ROUTES.Password)}
        >
          <LockOutlinedIcon />
          <ListItemText sx={{ ml: 1 }}>Password</ListItemText>
          <ChevronRightOutlinedIcon />
        </MenuItem>

        <MenuItem
          sx={{ backgroundColor: "white", borderRadius: "4px" }}
          onClick={() => navigate(APP_ROUTES.History)}
        >
          <HistoryOutlinedIcon />
          <ListItemText sx={{ ml: 1 }}>History</ListItemText>
          <ChevronRightOutlinedIcon />
        </MenuItem>
      </Box>

      <Button
        variant="text"
        color="error"
        size="large"
        onClick={handleLogout}
        sx={{ marginTop: "auto" }}
      >
        Log out
      </Button>
    </Box>
  );
};

export default SettingPage;
