import { Box, Card, CardContent, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import HttpService from "../../hooks/Https-services";
import { API_ROUTES } from "../../utils/constants";
import withdraw from "../../assets/images/withdraw.png";
import deposite from "../../assets/images/deposite.png";
import HistoryCards from "./HistoryCards";

const History = () => {
  const [data, setData] = useState<any>([]);
  const [isLoading, setLoading] = useState(false);
  const array = new Array(6).fill(0);
  const getHistory = async () => {
    try {
      const data = {
        // options: {
        //   page: 1,
        //   paginate: 10,
        // },
      };
      setLoading(true);
      const res = await HttpService.post(API_ROUTES.GetHistory, data);
      setData(res.data.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    getHistory();
  }, []);
  return (
    <Box>
      <Box
        sx={{ display: "flex", flexDirection: "column", gap: 1, mt: 3, pb: 2 }}
      >
        {!isLoading ? (
          data.length > 0 ? (
            data?.map((ele: any) => (
              <HistoryCards
                ammount={ele.amount}
                type={ele.transferType}
                dateTime={ele.createdAt}
                status={ele.paymentStatus}
              />
            ))
          ) : (
            <Box mx={"auto"}>
              <Typography>No data found</Typography>
            </Box>
          )
        ) : (
          array.map((ele: any) => (
            <Skeleton variant="rounded" width={"100%"} height={80} />
          ))
        )}
      </Box>
    </Box>
  );
};

export default History;
