import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermAndCondition from "./pages/TermAndCondition/index copy";
import PublicRoute from "./RouteAccessor/PublicRoute";
import { APP_ROUTES } from "./utils/constants";
import Login from "./pages/Login";
import OtpVerification from "./pages/OtpVerification";
import PrivateRoute from "./RouteAccessor/PrivateRoute";
import NotFound from "./pages/NotFound/NotFound";
import GameList from "./pages/GameList";
import SettingPage from "./pages/SettingPage";
import Profile from "./pages/Profile";
import UpdatePassword from "./pages/UpdatePassword";
import History from "./pages/History";
import ContactUS from "./pages/ContactUs";
import BankData from "./pages/BankData";
import AdminPage from "./pages/AdminPage";
import AdminRequest from "./pages/AdminRequest";
import ForgotPassword from "./pages/ForgotPassowrd";
import Register from "./pages/Register";
import HomePage from "./pages/HomePage";
const ProjectRoutes = () => {
  return (
    <React.Suspense fallback={<>Loading...</>}>
      <Router>
        <Routes>
          <Route
            path={APP_ROUTES.Landing}
            element={
              <PublicRoute>
                <LandingPage />
              </PublicRoute>
            }
          />
          <Route
            path={APP_ROUTES.Term}
            element={
              <PublicRoute>
                <TermAndCondition />
              </PublicRoute>
            }
          />
          <Route
            path={APP_ROUTES.Privacy}
            element={
              <PublicRoute>
                <PrivacyPolicy />
              </PublicRoute>
            }
          />
          <Route
            path={APP_ROUTES.Register}
            element={
              <PublicRoute>
                <Register />
              </PublicRoute>
            }
          />
          <Route
            path={APP_ROUTES.Forgot}
            element={
              <PublicRoute>
                <ForgotPassword />
              </PublicRoute>
            }
          />
          {/* <Route
            path={APP_ROUTES.OtpVerification}
            element={
              <PublicRoute>
                <OtpVerification />
              </PublicRoute>
            }
          /> */}
          <Route
            path={APP_ROUTES.ContactUs}
            element={
              <PublicRoute>
                <ContactUS />
              </PublicRoute>
            }
          />
          <Route
            path={APP_ROUTES.Login}
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path={APP_ROUTES.Home}
            element={
              <PrivateRoute>
                <HomePage />
              </PrivateRoute>
            }
          />
          <Route
            path={APP_ROUTES.GameList}
            element={
              <PrivateRoute>
                <GameList />
              </PrivateRoute>
            }
          />
          <Route
            path={APP_ROUTES.SettingPage}
            element={
              <PrivateRoute>
                <SettingPage />
              </PrivateRoute>
            }
          />
          <Route
            path={APP_ROUTES.Profile}
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />
          <Route
            path={APP_ROUTES.Password}
            element={
              <PrivateRoute>
                <UpdatePassword />
              </PrivateRoute>
            }
          />
          <Route
            path={APP_ROUTES.History}
            element={
              <PrivateRoute>
                <History />
              </PrivateRoute>
            }
          />
          <Route
            path={APP_ROUTES.Bank}
            element={
              <PrivateRoute>
                <BankData />
              </PrivateRoute>
            }
          />
          <Route
            path={APP_ROUTES.Admin}
            element={
              <PrivateRoute>
                <AdminPage />
              </PrivateRoute>
            }
          />
          <Route
            path={APP_ROUTES.AdminRequest}
            element={
              <PrivateRoute>
                <AdminRequest />
              </PrivateRoute>
            }
          />
          <Route
            path={APP_ROUTES.Any}
            element={
              <PublicRoute>
                <NotFound />
              </PublicRoute>
            }
          />
        </Routes>
      </Router>
    </React.Suspense>
  );
};

export default ProjectRoutes;
