const Style = {
  PrivacyParaStyle: { lineHeight: "30px", textAlign: "justify" },
  PrivacyHeading: {
    fontSize: { xs: "16px", md: "20px" },
    fontWeight: 500,
  },
  PrivacyBox: { display: "flex", flexDirection: "column", gap: "1rem" },
  ListItemStyle: {
    listStyleType: "disc",
    padding: 0,
    display: "list-item",
    my: 1,
  },
  AccordianBox: {
    mb: 2,
    boxShadow: "0px 4.6502px 14.8806px rgba(8, 15, 52, 0.06)",

    borderRadius: "12px",
    py: 2,

    "&.MuiPaper-root:first-of-type": {
      borderRadius: "12px",
    },
    "&.MuiPaper-root:last-of-type": {
      borderRadius: "12px",
    },
    "&.Mui-expanded": {
      border: "1px solid blue",
    },
    "&.MuiPaper-root:before": {
      backgroundColor: "transparent",
    },
  },
};
export default Style;
