import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import HttpService from "../../../hooks/Https-services";
import { API_ROUTES, APP_ROUTES } from "../../../utils/constants";
import PopMenu from "../PopMenu";
import moment from "moment";
import HistoryTableRow from "./HistoryTableRow";

const PopHistory = (props: any) => {
  const { id } = props;
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [row, setRow] = useState(10);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [isRefresh, setIsRefresh] = useState(false);
  const getHistory = async (row: any, page: any) => {
    try {
      setIsLoading(true);
      const data = {
        query: {
          userId: id,
        },
        options: {
          page: page,
          paginate: row,
        },
        isCountOnly: false,
      };
      const res = await HttpService.post(API_ROUTES.GetUserHistory, data);
      setData(res.data.data.data);
      setCount(res.data.data.paginator.itemCount);
      setRow(res.data.data.paginator.perPage);
      setPage(res.data.data.paginator.currentPage);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const r = parseInt(event.target.value, 10);
    getHistory(r, 1);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    getHistory(row, newPage + 1);
  };
  useEffect(() => {
    if (open) {
      getHistory(row, 1);
    }
  }, [open, isRefresh]);
  return (
    <>
      <Button variant="contained" onClick={() => setOpen(true)}>
        History
      </Button>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setData(null);
        }}
        maxWidth="lg"
        fullWidth
        sx={{
          width: 1,
          ".MuiPaper-root": isLoading
            ? { overflow: "hidden", background: "transparent" }
            : "",
        }}
      >
        {!isLoading ? (
          <TableContainer
            sx={{ maxWidth: "1200px", width: "100% " }}
            component={Paper}
          >
            <Table sx={{ width: 1 }}>
              <TableHead
                sx={{
                  backgroundColor: "primary.main",

                  ".MuiTableCell-root": {
                    // px: "5px",
                    color: "white",
                  },
                }}
              >
                <TableRow>
                  <TableCell align="center">No</TableCell>
                  <TableCell>Transaction Type</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Added By</TableCell>
                  <TableCell>Created Time</TableCell>
                  <TableCell>Updated Time</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>

              {data && data.length > 0 ? (
                <TableBody>
                  {data.map((ele: any, idx: any) => (
                    <HistoryTableRow
                      idx={idx}
                      ele={ele}
                      refresh={isRefresh}
                      setRefresh={setIsRefresh}
                      page={page}
                      row={row}
                      id={id}
                    />
                  ))}
                </TableBody>
              ) : (
                <TableRow>
                  <TableCell colSpan={6} sx={{ py: 4 }}>
                    <Typography textAlign="center">No data found</Typography>
                  </TableCell>
                </TableRow>
              )}
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              rowsPerPage={row}
              count={count}
              page={page - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        ) : (
          <CircularProgress
            size={60}
            sx={{
              margin: "auto",
            }}
          />
        )}
      </Dialog>
    </>
  );
};

export default PopHistory;
