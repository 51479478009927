import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import { FC, useEffect, useState } from "react";
import Style from "./TermAndCondition.style";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const TermAndCondition: FC = () => {
  function topFunction() {
    window.scroll({ top: 0, behavior: "smooth" });
  }
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  useEffect(() => {
    topFunction();
  }, []);
  return (
    <Container maxWidth="xl" sx={{ padding: { xs: 4, md: 3 } }}>
      <Box sx={{ p: { xs: 3, md: 8 } }}>
        <Typography
          variant="h1"
          sx={{
            fontSize: { xs: "36px", md: "48px" },
            color: "black",
            textAlign: "center",
            fontWeight: 500,
            my: { xs: 4, md: 6 },
          }}
        >
          Terms & Conditions
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: { xs: "1rem", md: "1rem" },
            mt: { xs: "2rem", md: "4rem" },
          }}
        >
          <Typography sx={Style.PrivacyParaStyle}>
            Terms and conditions for software use please read these terms and
            conditions carefully Before using this software. By using this
            software, you agree to be bound by these terms And conditions. If
            you do not agree to these terms and conditions, do not use this
            SOFTWARE.
          </Typography>
          <Accordion
            sx={Style.AccordianBox}
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h1" sx={Style.PrivacyHeading}>
                1. LICENSE GRANT
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={Style.PrivacyParaStyle}>
                {`The software and any accompanying documentation (collectively, the Software ) is
            licensed, not sold, to you by the software owner - SparrowWeb. The Owner grants you
            a non-exclusive, non-transferable license to use the Software in accordance with these
            terms and conditions`}
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
            sx={Style.AccordianBox}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h1" sx={Style.PrivacyHeading}>
                2. RESTRICTIONS
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={Style.PrivacyParaStyle}>
                You may not, without the prior written consent of the Owner:
              </Typography>
              <List
                sx={{
                  pl: 5,
                }}
              >
                <ListItem sx={Style.ListItemStyle}>
                  Copy, distribute, or modify the Software;{" "}
                </ListItem>
                <ListItem sx={Style.ListItemStyle}>
                  {" "}
                  Reverse engineer, decompile, or disassemble the Software;{" "}
                </ListItem>
                <ListItem sx={Style.ListItemStyle}>
                  {" "}
                  Remove any copyright, trademark, or other proprietary notices
                  from the Software or any documentation;
                </ListItem>
                <ListItem sx={Style.ListItemStyle}>
                  {" "}
                  Use the Software to create a competitive product or service;
                </ListItem>
                <ListItem sx={Style.ListItemStyle}>
                  {" "}
                  Use the Software in any manner that violates any applicable
                  laws or regulations.
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
            sx={Style.AccordianBox}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h1" sx={Style.PrivacyHeading}>
                3. OWNERSHIP
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={Style.PrivacyParaStyle}>
                The Software and any copies thereof are the property of the
                Owner or its licensors and are protected by copyright laws and
                international treaty provisions. The Owner retains all rights,
                title, and interest in and to the Software, including all
                intellectual property rights.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
            sx={Style.AccordianBox}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h1" sx={Style.PrivacyHeading}>
                4. WARRANTIES
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={Style.PrivacyParaStyle}>
                {`The software is provided "as is" without warranty of any kind, either express or
            Implied, including, but not limited to, the implied warranties of merchantability and
            Fitness for a particular purpose. The owner does not warrant that the software will meet
            Your requirements or that the operation of the software will be uninterrupted or
            Error-free.`}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
            sx={Style.AccordianBox}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h1" sx={Style.PrivacyHeading}>
                5. LIMITATION OF LIABILITY
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={Style.PrivacyParaStyle}>
                In no event shall the owner be liable for any direct, indirect,
                incidental, special, or Consequential damages arising out of or
                in connection with the use or inability to use The software,
                even if the owner has been advised of the possibility of such
                damages.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel10"}
            onChange={handleChange("panel10")}
            sx={Style.AccordianBox}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h1" sx={Style.PrivacyHeading}>
                6. TERMINATION
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={Style.PrivacyParaStyle}>
                This license will terminate automatically if you fail to comply
                with any of the terms and conditions of this agreement. Upon
                termination, you must immediately cease all use of the Software
                and destroy all copies of the Software.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel6"}
            onChange={handleChange("panel6")}
            sx={Style.AccordianBox}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h1" sx={Style.PrivacyHeading}>
                7. GOVERNING LAW
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={Style.PrivacyParaStyle}>
                This agreement shall be governed by and construed in accordance
                with the laws of the jurisdiction in which the Owner is located.
                Any disputes arising under or in connection with this agreement
                shall be resolved by the courts of that jurisdiction.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel7"}
            onChange={handleChange("panel7")}
            sx={Style.AccordianBox}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h1" sx={Style.PrivacyHeading}>
                8. ENTIRE AGREEMENT
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={Style.PrivacyParaStyle}>
                This agreement constitutes the entire agreement between you and
                the Owner with respect to the use of the Software and supersedes
                all prior or contemporaneous communications and proposals,
                whether oral or written, between you and the Owner.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel8"}
            onChange={handleChange("panel8")}
            sx={Style.AccordianBox}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h1" sx={Style.PrivacyHeading}>
                9. SEVERABILITY
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={Style.PrivacyParaStyle}>
                If any provision of this agreement is held to be invalid or
                unenforceable, the remaining provisions shall remain in full
                force and effect.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel9"}
            onChange={handleChange("panel9")}
            sx={Style.AccordianBox}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h1" sx={Style.PrivacyHeading}>
                10. ACKNOWLEDGMENT
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={Style.PrivacyParaStyle}>
                By using the Software, you acknowledge that you have read this
                agreement, understand it, and agree to be bound by its terms and
                conditions.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Box sx={Style.PrivacyBox} style={{ marginBottom: "24px" }}>
            <Typography variant="h1" sx={Style.PrivacyHeading}></Typography>
            <Typography sx={Style.PrivacyParaStyle}></Typography>
            <Typography sx={Style.PrivacyParaStyle}>
              If you have any questions regarding these terms and conditions,
              please contact{" "}
              <a href="mailto:Info@chatmatrix.io">{`SparrowWeb`}</a>.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};
export default TermAndCondition;
