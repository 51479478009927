import { Box, Container } from "@mui/material";
import React from "react";
// import Header from '../Header';
import Header from "../Header";
import bg from "../../assets/images/background.png";
import { PrivateHeader } from "../PrivateHeader";
import { useLocation } from "react-router-dom";
import { APP_ROUTES } from "../../utils/constants";
import PrivateFooter from "../PrivateFooter";

const MainContainer = ({ children }: { children: React.ReactElement }) => {
  const location = useLocation();
  return (
    <Box
      sx={{
        minHeight: "100svh",
        display: "flex",
        flexDirection: "column",
        maxWidth: "100%",
      }}
    >
      <PrivateHeader />
      <Box
        sx={{
          width: "100%",
          backgroundImage:
            location.pathname == APP_ROUTES.GameList ||
            location.pathname == APP_ROUTES.Home
              ? `url(${bg})`
              : "",
          backgroundSize: "cover",
          backgroundColor: "whitesmoke",
          backgroundPosition: "center",
          objectFit: "containt",
          flexGrow: 1,
          display: "flex",
        }}
      >
        <Container
          maxWidth={location.pathname.includes(APP_ROUTES.Admin) ? false : "xs"}
          sx={{ flexGrow: 1 }}
        >
          {children}
        </Container>
      </Box>
    </Box>
  );
};

export default MainContainer;
