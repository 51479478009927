import { createTheme } from "@mui/material";
import { red } from "@mui/material/colors";

let theme = createTheme({
  palette: {
    primary: {
      light: "#4A3AFF",
      main: "#6b4eff",
      dark: "#6B4EFF",
      contrastText: "#fff",
    },
    secondary: {
      light: "#067ae6",
      main: "#fff",
      dark: "#FFFFFF",
      contrastText: "#000",
    },
    error: {
      main: red.A700,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        // Styles for the "contained" variant
        contained: {
          borderRadius: "48px", // Example: Set border radius for contained variant
          textTransform: "none", // Example: Remove text transformation
          // Add other styles for the contained variant
        },
        outlined: {
          borderRadius: "48px", // Example: Set border radius for contained variant
          textTransform: "none", // Example: Remove text transformation
          // Add other styles for the contained variant
        },
        text: {
          borderRadius: "48px", // Example: Set border radius for contained variant
          textTransform: "none", // Example: Remove text transformation
          // Add other styles for the contained variant
        },
      },
    },
  },
});

export default theme;
