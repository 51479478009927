import {
  Box,
  Button,
  Container,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { API_ROUTES, APP_ROUTES } from "../../utils/constants";
import PhoneInput from "react-phone-number-input";
import "./style.css";
import "react-phone-number-input/style.css";
import HttpService from "../../hooks/Https-services";
import { toast } from "react-toastify";
import LoadingButton from "@mui/lab/LoadingButton";
import commonDetailSlice from "../../store/reducers/commonReducer";
import { useDispatch } from "react-redux";
import { setAuth } from "../../hooks/useRefreshToken";

const Register = () => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm();
  const [load, setLoad] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const dispatch = useDispatch();
  async function getEntries(data: any) {
    delete data.tokens;
    dispatch(commonDetailSlice.actions.removecommonDetails());
    dispatch(commonDetailSlice.actions.setcommonDetails(data));
  }
  const handleChangePhone = (value: any) => {
    setPhoneNumber(value);
  };
  const onSubmit = async (data: any) => {
    if (data.password !== data.confirmPassword) {
      toast.error("Password not match.");
      return;
    }
    try {
      const dataBody = {
        password: data.password,
        email: data.email,
        name: data.username,
        phoneNo: phoneNumber,
      };
      setLoad(true);
      const res = await HttpService.post(API_ROUTES.Register, dataBody);
      getEntries(res.data.data);
      setAuth(res.data.data);
      toast.success("Register successfully");
      navigate(APP_ROUTES.Home);
      setLoad(false);
    } catch (error) {
      setLoad(false);
      console.log(error);
    }
  };
  const navigate = useNavigate();
  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container
          maxWidth="xs"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            pb: 2,
            alignItems: "center",
          }}
        >
          <Typography variant="h5" fontWeight={600} className="as">
            Create Free Account
          </Typography>
          <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
            <Box sx={{ mb: 1 }}>
              <label htmlFor="number">Phone Number</label>
            </Box>
            <Box
              sx={{
                border: "1px solid #EBEBEB",
                borderRadius: "4px",
                px: "14px",
                "&:hover": {
                  outline: "0.8px solid black",
                },
                "&:focus-within": {
                  outline: "1.9px solid #436af3 !important",
                },
              }}
            >
              <PhoneInput
                international
                autoFormat={false}
                defaultCountry="IN"
                country="US"
                onChange={handleChangePhone}
                countries={["IN"]}
              />
            </Box>
          </FormControl>
          <TextField
            fullWidth
            label="Username"
            variant="outlined"
            error={!!errors.username}
            {...register("username", {
              required: "This field is required.",
            })}
            helperText={
              errors.username && (
                <Typography variant="caption" sx={{ color: "red" }}>
                  {errors.username.message as any}
                </Typography>
              )
            }
          />
          <TextField
            label="Email"
            type="email"
            fullWidth
            // {...register("email", {
            //   required: "This field is required.",
            //   pattern: {
            //     value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            //     message: "Please enter valid email.",
            //   },
            // })}
            // error={!!errors.email}
            // helperText={
            //   errors.email && (
            //     <Typography variant="caption" sx={{ color: "red" }}>
            //       {errors.email.message as any}
            //     </Typography>
            //   )
            // }
          />
          <TextField
            label="password"
            type="password"
            fullWidth
            {...register("password", {
              required: "This field is required.",
              minLength: {
                value: 8,
                message: "Password must be at least 8 characters long.",
              },
            })}
            error={!!errors.password}
            helperText={
              errors.password && (
                <Typography variant="caption" sx={{ color: "red" }}>
                  {errors.password.message as any}
                </Typography>
              )
            }
          />
          <TextField
            fullWidth
            label="Confirm Password"
            type="password"
            variant="outlined"
            size="medium"
            {...register("confirmPassword", {
              required: "This field is required.",
            })}
            error={!!errors.confirmPassword}
            helperText={
              errors.confirmPassword && (
                <Typography variant="caption" sx={{ color: "red" }}>
                  {errors.confirmPassword.message as any}
                </Typography>
              )
            }
          />
          <Box sx={{ width: "100%" }}>
            <Typography
              variant="body2"
              mt={1}
              fontWeight={500}
              textAlign="left"
            >
              Already have account{" "}
              <Typography
                component="span"
                variant="body2"
                color={"primary.main"}
                onClick={() => navigate(APP_ROUTES.Login)}
                sx={{ cursor: "pointer" }}
              >
                {" "}
                click
              </Typography>{" "}
              here for sign in.
            </Typography>
          </Box>
          <Typography variant="body2" mt={1} fontWeight={500}>
            By continuing, you agree to our{" "}
            <Typography
              component="span"
              variant="body2"
              color={"primary.main"}
              onClick={() => navigate(APP_ROUTES.Term)}
              sx={{ cursor: "pointer" }}
            >
              {" "}
              Terms of Service
            </Typography>{" "}
            and{" "}
            <Typography
              component="span"
              variant="body2"
              color={"primary.main"}
              onClick={() => navigate(APP_ROUTES.Term)}
              sx={{ cursor: "pointer" }}
            >
              {" "}
              Privacy Policy
            </Typography>
            .
          </Typography>

          <LoadingButton
            loading={load}
            variant="contained"
            size="large"
            type="submit"
            fullWidth
            sx={{ mt: 2 }}
          >
            Register
          </LoadingButton>
        </Container>
      </form>
    </Box>
  );
};

export default Register;
