import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { Routes } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import ProjectRoutes from "./Routes";
import { ThemeProvider } from "@mui/material";
import theme from "./Style/theme";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store from "./store/store";
import { persistStore } from "redux-persist";
import { ToastContainer } from "react-toastify";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Analytics } from "@vercel/analytics/react";
function App() {
  const persistor = persistStore(store);
  return (
    <ThemeProvider theme={theme}>
      <ToastContainer
        position="top-right"
        autoClose={2500}
        limit={2}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        icon={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
      />
      <SpeedInsights />
      <Analytics />
      <ProjectRoutes />
    </ThemeProvider>
  );
}

export default App;
