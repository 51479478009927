import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { API_ROUTES, APP_ROUTES } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import HttpService from "../../hooks/Https-services";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import commonDetailSlice from "../../store/reducers/commonReducer";
import { setAuth } from "../../hooks/useRefreshToken";
import LoadingButton from "@mui/lab/LoadingButton";

const Login = () => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);
  async function getEntries(data: any) {
    delete data.tokens;
    dispatch(commonDetailSlice.actions.removecommonDetails());
    dispatch(commonDetailSlice.actions.setcommonDetails(data));
  }
  const [phoneNumber, setPhoneNumber] = useState("");
  const handleChangePhone = (value: any) => {
    setPhoneNumber(value);
  };
  const [phoneNumber2, setPhoneNumber2] = useState("");
  const handleChangePhone2 = (value: any) => {
    setPhoneNumber2(value);
  };
  const navigate = useNavigate();
  const onSubmit = async (data: any) => {
    try {
      setLoad(true);
      if (phoneNumber2) {
        const dataBody = {
          phoneNo: phoneNumber2,
        };
        const res = await HttpService.post(API_ROUTES.LoginWithOtp, dataBody);
        toast.success("Otp send successfully");
        // navigate(APP_ROUTES.OtpVerification, { state: phoneNumber2 });
      } else {
        const dataBody = {
          phoneNo: phoneNumber,
          password: data.password,
        };
        const res = await HttpService.post(API_ROUTES.Login, dataBody);
        getEntries(res.data.data);
        setAuth(res.data.data);
        navigate(APP_ROUTES.Home);
        toast.success("Login successfully");
        setLoad(false);
      }
    } catch (error) {
      setLoad(false);
      console.log(error);
    }
  };
  return (
    <Box pb={2}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container
          maxWidth="xs"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            alignItems: "center",
          }}
        >
          <Typography variant="h5" fontWeight={600} className="as">
            Login
          </Typography>
          <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
            <Box sx={{ mb: 1 }}>
              <label htmlFor="number">Phone Number</label>
            </Box>
            <Box
              sx={{
                border: "1px solid #EBEBEB",
                borderRadius: "4px",
                px: "14px",
                "&:hover": {
                  outline: "0.8px solid black",
                },
                "&:focus-within": {
                  outline: "1.9px solid #436af3 !important",
                },
              }}
            >
              <PhoneInput
                international
                autoFormat={false}
                defaultCountry="IN"
                country="IN"
                disabled={!!phoneNumber2}
                onChange={handleChangePhone}
                countries={["IN"]}
              />
            </Box>
          </FormControl>

          <TextField
            label="password"
            type="password"
            fullWidth
            disabled={!!phoneNumber2}
            {...register("password", {
              // required: "This field is required.",
              // minLength: {
              //   value: 8,
              //   message: "Password must be at least 8 characters long.",
              // },
            })}
            // error={!!errors.password}
            // helperText={
            //   errors.password && (
            //     <Typography variant="caption" sx={{ color: "red" }}>
            //       {errors.password.message as any}
            //     </Typography>
            //   )
            // }
          />
          <Typography
            variant="body2"
            mt={-1.5}
            ml={"auto"}
            sx={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => navigate(APP_ROUTES.Forgot)}
          >
            Forgot Password?
          </Typography>

          <Typography variant="body2" fontWeight={500}>
            By continuing, you agree to our{" "}
            <Typography
              component="span"
              variant="body2"
              color={"#6b4eff"}
              onClick={() => navigate(APP_ROUTES.Term)}
              sx={{ cursor: "pointer" }}
            >
              {" "}
              Terms of Service
            </Typography>{" "}
            and{" "}
            <Typography
              component="span"
              variant="body2"
              color={"#6b4eff"}
              onClick={() => navigate(APP_ROUTES.Term)}
              sx={{ cursor: "pointer" }}
            >
              {" "}
              Privacy Policy
            </Typography>
            .
          </Typography>

          <LoadingButton
            loading={load}
            variant="contained"
            size="large"
            type="submit"
            fullWidth
            sx={{ mt: 2 }}
          >
            Login
          </LoadingButton>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              gap: 1,
            }}
          >
            <Typography
              variant="body2"
              mt={2}
              fontWeight={500}
              sx={{ marginLeft: "auto" }}
            >
              Don't have an account?
            </Typography>
            <Box>
              <Button
                variant="outlined"
                onClick={() => navigate(APP_ROUTES.Register)}
              >
                {" "}
                Create Account
              </Button>
            </Box>
          </Box>
        </Container>
      </form>
    </Box>
  );
};

export default Login;
