import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  InputAdornment,
  Stack,
  TableContainer,
  TextField,
  Typography,
  Paper,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Dialog,
  Skeleton,
  debounce,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import HttpService from "../../hooks/Https-services";
import { API_ROUTES } from "../../utils/constants";
import { useForm } from "react-hook-form";
import moment from "moment";

const AdminRequest = () => {
  const [data, setData] = useState([]);
  const [row, setRow] = useState(10);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState("");
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm();
  const getUserList = async (row: any, page: any) => {
    try {
      const data = {
        query: {},
        options: {
          page: page,
          paginate: row,
        },
        filter: filter,
      };
      const res = await HttpService.post(API_ROUTES.RequestedUserList, data);
      setData(res.data.data.data);
      setCount(res.data.data.paginator.itemCount);
      setRow(res.data.data.paginator.perPage);
      setPage(res.data.data.paginator.currentPage);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  const handleSearch = debounce((e: any) => {
    setFilter(e?.target?.value);
  }, 500);

  useEffect(() => {
    getUserList(row, 1);
  }, [filter]);
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const r = parseInt(event.target.value, 10);
    getUserList(r, 1);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    getUserList(row, newPage + 1);
  };
  const handleTransition = async (id: any, type: any, action: any) => {
    try {
      const dataBody = {
        transactionId: id,
        transferType: type,
        action: action,
      };
      setIsLoading(true);
      const res = await HttpService.post(API_ROUTES.HandleRequest, dataBody);
      getUserList(row, page);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Box>
      {!isLoading ? (
        <>
          <Typography variant="h5" textAlign="center" my={2} fontWeight={700}>
            User Applications
          </Typography>
          <form onSubmit={handleSubmit(handleSearch)}>
            <Box mt={2} sx={{ display: "flex", gap: 2 }}>
              <TextField
                size="small"
                variant="outlined"
                placeholder="Search"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  sx: {
                    backgroundColor: "white",
                  },
                }}
                onChange={(e) => handleSearch(e)}
              />
            </Box>
          </form>
          <Box mt={2}>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead
                  sx={{
                    ".MuiTableCell-root": {},
                  }}
                >
                  <TableRow>
                    <TableCell align="center">No</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Phone Number</TableCell>
                    <TableCell>Transaction Type</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Time</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                {data.length > 0 ? (
                  <TableBody>
                    {data.map((ele: any, idx: any) => (
                      <TableRow
                        key={idx}
                        sx={{
                          "&:nth-of-type(odd)": {
                            backgroundColor: "lightgray",
                          },
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell align="center">
                          {(page - 1) * row + (idx + 1)}
                        </TableCell>
                        <TableCell>{ele.user.name}</TableCell>
                        <TableCell>{ele.user.email}</TableCell>
                        <TableCell>{ele.user.phoneNo}</TableCell>
                        {ele?.transferType == 1 && (
                          <TableCell>Added money</TableCell>
                        )}
                        {ele?.transferType == 2 && (
                          <TableCell>Withdrawal money</TableCell>
                        )}
                        {ele?.transferType == 3 && (
                          <TableCell>Bid Placed</TableCell>
                        )}
                        {ele?.transferType == 4 && (
                          <TableCell>Winning amount</TableCell>
                        )}

                        <TableCell
                          sx={{
                            color:
                              ele?.transferType == 1
                                ? "success.main"
                                : ele?.transferType == 2
                                ? "error.main"
                                : ele?.transferType == 4
                                ? "success.main"
                                : "",
                          }}
                        >
                          {ele?.amount}
                        </TableCell>
                        <TableCell>
                          {moment(ele?.createdAt)
                            .tz("Asia/Kolkata")
                            .format("YYYY-MM-DD HH:mm:ss")}
                        </TableCell>
                        <TableCell>
                          <Stack
                            spacing={2}
                            direction="row"
                            justifyContent="center"
                          >
                            <Button
                              variant="contained"
                              color="success"
                              onClick={() =>
                                handleTransition(ele?.id, ele?.transferType, 1)
                              }
                            >
                              Approve
                            </Button>
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() =>
                                handleTransition(ele?.id, ele?.transferType, 2)
                              }
                            >
                              Reject
                            </Button>
                            <Button
                              variant="outlined"
                              color="error"
                              onClick={() =>
                                handleTransition(ele?.id, ele?.transferType, 3)
                              }
                            >
                              Delete
                            </Button>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableRow>
                    <TableCell colSpan={8} sx={{ py: 5 }}>
                      <Typography textAlign="center">No data found</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                rowsPerPage={row}
                count={count}
                page={page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </Box>
        </>
      ) : (
        <Skeleton
          variant="rounded"
          width={"100%"}
          height={500}
          sx={{ mt: 4 }}
        />
      )}
    </Box>
  );
};

export default AdminRequest;
