import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  Card,
  CardContent,
  Stack,
  Typography,
  TextField,
  InputAdornment,
  CardActions,
  IconButton,
} from "@mui/material";
import { type } from "@testing-library/user-event/dist/type";
import React, { useState } from "react";
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
import HttpService from "../../../../hooks/Https-services";
import { API_ROUTES } from "../../../../utils/constants";
import { toast } from "react-toastify";

const PopUpdate = (props: any) => {
  const { id, setRefresh, refresh } = props;
  const [amount, setAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const hadleTransactions = async () => {
    try {
      setIsLoading(true);
      const data = {
        amount: amount,
      };
      const res = await HttpService.put(
        API_ROUTES.UpdateTranscation.replace(":id", id),
        data
      );
      toast.success("Amount updated successfully");
      setRefresh(!refresh);
      setOpen(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  return (
    <>
      <IconButton color="info" onClick={() => setOpen(true)}>
        <BorderColorRoundedIcon />
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Card sx={{ p: 1.5 }}>
          <CardContent>
            <Stack spacing={2}>
              <Typography variant="h6">Enter Updated Amount</Typography>

              <TextField
                type="number"
                variant="outlined"
                label="Amount"
                onChange={(e: any) => setAmount(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography variant="body1">Rs</Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
          </CardContent>
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <Stack spacing={1} direction="row">
              <Button variant="outlined" onClick={() => setOpen(false)}>
                Cancel
              </Button>
              <LoadingButton
                loading={isLoading}
                variant="contained"
                type="submit"
                onClick={hadleTransactions}
              >
                Confirm
              </LoadingButton>
            </Stack>
          </CardActions>
        </Card>
      </Dialog>
    </>
  );
};

export default PopUpdate;
