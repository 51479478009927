import { Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import withdraw from "../../../assets/images/withdraw.png";
import deposite from "../../../assets/images/deposite.png";
import win from "../../../assets/images/winn.png";
import bid from "../../../assets/images/bidplace.png";
import moment from "moment-timezone";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const HistoryCards = (props: any) => {
  const { dateTime, ammount, type, status } = props;
  const formattedDate = moment(dateTime)
    .tz("Asia/Kolkata")
    .format("D MMM, YYYY");
  const formattedTime = moment(dateTime).tz("Asia/Kolkata").format("h:mma");
  console.log(dateTime);
  return (
    <Box>
      {type == 1 ? (
        <Card sx={{ borderRadius: "8px" }}>
          <CardContent
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              p: "12px !important",
            }}
          >
            <Box component="img" src={deposite} width={50} />
            <Box width={"100%"}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="body1" fontWeight={500}>
                  Added money
                </Typography>
                <Typography
                  variant="body1"
                  fontWeight={500}
                  color="success.main"
                >
                  Rs. {ammount}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="body2" color={"#696969"}>
                  {formattedDate}
                </Typography>
                <Typography variant="body2" color={"#696969"}>
                  {formattedTime}
                </Typography>
              </Box>
              {status == 1 ? (
                <Typography
                  variant="body2"
                  fontWeight={500}
                  color={"success.main"}
                  sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                >
                  <FiberManualRecordIcon
                    sx={{ color: "success.main", fontSize: "1.2em" }}
                  />
                  Success
                </Typography>
              ) : status == 0 ? (
                <Typography
                  variant="body2"
                  fontWeight={500}
                  color={"error.main"}
                  sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                >
                  <FiberManualRecordIcon
                    sx={{ color: "error.main", fontSize: "1.2em" }}
                  />
                  Failed
                </Typography>
              ) : (
                <Typography
                  variant="body2"
                  fontWeight={500}
                  color={"info.main"}
                  sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                >
                  <FiberManualRecordIcon
                    sx={{ color: "info.main", fontSize: "1.2em" }}
                  />
                  Pending
                </Typography>
              )}
            </Box>
          </CardContent>
        </Card>
      ) : type == 2 ? (
        <Card sx={{ borderRadius: "8px" }}>
          <CardContent
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              p: "12px !important",
            }}
          >
            <Box component="img" src={withdraw} width={50} />
            <Box width={"100%"}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="body1" fontWeight={500}>
                  Withdrawal money
                </Typography>
                <Typography variant="body1" fontWeight={500} color="error">
                  Rs. {ammount}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="body2" color={"#696969"}>
                  {formattedDate}
                </Typography>
                <Typography variant="body2" color={"#696969"}>
                  {formattedTime}
                </Typography>
              </Box>
              {status == 1 ? (
                <Typography
                  variant="body2"
                  fontWeight={500}
                  color={"success.main"}
                  sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                >
                  <FiberManualRecordIcon
                    sx={{ color: "success.main", fontSize: "1.2em" }}
                  />
                  Success
                </Typography>
              ) : status == 0 ? (
                <Typography
                  variant="body2"
                  fontWeight={500}
                  color={"error.main"}
                  sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                >
                  <FiberManualRecordIcon
                    sx={{ color: "error.main", fontSize: "1.2em" }}
                  />
                  Failed
                </Typography>
              ) : (
                <Typography
                  variant="body2"
                  fontWeight={500}
                  color={"info.main"}
                  sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                >
                  <FiberManualRecordIcon
                    sx={{ color: "info.main", fontSize: "1.2em" }}
                  />
                  Pending
                </Typography>
              )}
            </Box>
          </CardContent>
        </Card>
      ) : type == 3 ? (
        <Card sx={{ borderRadius: "8px" }}>
          <CardContent
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              p: "12px !important",
            }}
          >
            <Box component="img" src={bid} width={50} />
            <Box width={"100%"}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="body1" fontWeight={500}>
                  Bid Placed
                </Typography>
                <Typography variant="body1" fontWeight={500} color={"#696969"}>
                  Rs. {ammount}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="body2" color={"#696969"}>
                  {formattedDate}
                </Typography>
                <Typography variant="body2" color={"#696969"}>
                  {formattedTime}
                </Typography>
              </Box>
              {status == 1 ? (
                <Typography
                  variant="body2"
                  fontWeight={500}
                  color={"success.main"}
                  sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                >
                  <FiberManualRecordIcon
                    sx={{ color: "success.main", fontSize: "1.2em" }}
                  />
                  Success
                </Typography>
              ) : status == 0 ? (
                <Typography
                  variant="body2"
                  fontWeight={500}
                  color={"error.main"}
                  sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                >
                  <FiberManualRecordIcon
                    sx={{ color: "error.main", fontSize: "1.2em" }}
                  />
                  Failed
                </Typography>
              ) : (
                <Typography
                  variant="body2"
                  fontWeight={500}
                  color={"info.main"}
                  sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                >
                  <FiberManualRecordIcon
                    sx={{ color: "info.main", fontSize: "1.2em" }}
                  />
                  Pending
                </Typography>
              )}
            </Box>
          </CardContent>
        </Card>
      ) : (
        <Card sx={{ borderRadius: "8px" }}>
          <CardContent
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              p: "12px !important",
            }}
          >
            <Box component="img" src={win} width={50} />
            <Box width={"100%"}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body1"
                  fontWeight={500}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  Winning amount
                </Typography>

                <Typography
                  variant="body1"
                  fontWeight={500}
                  color="primary.main"
                >
                  Rs. {ammount}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="body2" color={"#696969"}>
                  {formattedDate}
                </Typography>
                <Typography variant="body2" color={"#696969"}>
                  {formattedTime}
                </Typography>
              </Box>

              {status == 1 ? (
                <Typography
                  variant="body2"
                  fontWeight={500}
                  color={"success.main"}
                  sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                >
                  <FiberManualRecordIcon
                    sx={{ color: "success.main", fontSize: "1.2em" }}
                  />
                  Success
                </Typography>
              ) : status == 0 ? (
                <Typography
                  variant="body2"
                  fontWeight={500}
                  color={"error.main"}
                  sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                >
                  <FiberManualRecordIcon
                    sx={{ color: "error.main", fontSize: "1.2em" }}
                  />
                  Failed
                </Typography>
              ) : (
                <Typography
                  variant="body2"
                  fontWeight={500}
                  color={"info.main"}
                  sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                >
                  <FiberManualRecordIcon
                    sx={{ color: "info.main", fontSize: "1.2em" }}
                  />
                  Pending
                </Typography>
              )}
            </Box>
          </CardContent>
        </Card>
      )}
    </Box>
  );
};

export default HistoryCards;
