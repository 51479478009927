import { Box, Button, useMediaQuery } from "@mui/material";
import React from "react";
import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router";
import { APP_ROUTES } from "../../utils/constants";

const Header = () => {
  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down("sm")
  );
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        px: { xs: 2, sm: 4, md: 10 },
        position: "sticky",
        top: 0,
        zIndex: 999,
        backgroundColor: "#FFF",
        borderBottom: "1px solid lightgray",
      }}
    >
      <Box>
        <Box
          component={"img"}
          src={logo}
          width={{ xs: 50, sm: 80 }}
          sx={{ cursor: "pointer" }}
          onClick={() => navigate(APP_ROUTES.Landing)}
        />
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Button
          onClick={() => navigate(APP_ROUTES.ContactUs)}
          size={isSmallScreen ? "small" : "large"}
          sx={{ fontWeight: 600 }}
        >
          Get Help
        </Button>
        <Button
          variant="contained"
          size={isSmallScreen ? "small" : "large"}
          onClick={() => navigate(APP_ROUTES.Login)}
        >
          Play Game
        </Button>
      </Box>
    </Box>
  );
};

export default Header;
