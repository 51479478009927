import {
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import HttpService from "../../../../hooks/Https-services";
import { API_ROUTES } from "../../../../utils/constants";
import { toast } from "react-toastify";
import PopUpdate from "../PopUpdate";

const HistoryTableRow = (props: any) => {
  const { idx, ele, setRefresh, refresh, page, row, id } = props;
  const [isEditable, setIsEditable] = useState(false);
  const ref = useRef<any>(null);

  const handleTime = async (e: any) => {
    const endTime = e.add(3, "minutes");
    ref.current = setInterval(() => {
      const remainingTime = moment.duration(
        endTime.diff(moment().tz("Asia/Kolkata"))
      );
      const minutes = remainingTime.minutes();
      const seconds = remainingTime.seconds();
      if (minutes <= 0 && seconds <= 0) {
        clearInterval(ref.current);
        setIsEditable(false);
        return;
        // Perform any action after the timer expires
      }
      // console.log(`${minutes} minutes and ${seconds} seconds remaining`);
      setIsEditable(true);
      const t = {
        min: minutes < 10 ? `0${minutes}` : minutes,
        sec: seconds < 10 ? `0${seconds}` : seconds,
      };
    }, 1000); // Call handleTime every 1 second
  };

  useEffect(() => {
    handleTime(moment(ele.updatedAt).tz("Asia/Kolkata"));
    return () => {
      clearInterval(ref.current);
    };
  }, []);
  const handleDelete = async () => {
    try {
      const res = await HttpService.del(
        API_ROUTES.DeleteTranscation.replace(":id", ele.id)
      );
      toast.success("Transcation deleted successfully");
      clearInterval(ref.current);
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <TableRow
      key={idx}
      sx={{
        "&:nth-of-type(even)": {
          backgroundColor: "lightgray",
        },
        "&:last-child td, &:last-child th": {
          border: 0,
        },
      }}
    >
      <TableCell align="center">{(page - 1) * row + (idx + 1)}</TableCell>
      {ele?.transferType == 1 && <TableCell>Added money</TableCell>}
      {ele?.transferType == 2 && <TableCell>Withdrawal money</TableCell>}
      {ele?.transferType == 3 && <TableCell>Bid Placed</TableCell>}
      {ele?.transferType == 4 && <TableCell>Winning amount</TableCell>}

      <TableCell
        sx={{
          color:
            ele?.transferType == 1
              ? "success.main"
              : ele?.transferType == 2
              ? "error.main"
              : ele?.transferType == 4
              ? "success.main"
              : "",
        }}
      >
        {ele?.amount}
      </TableCell>
      <TableCell>{ele?.admin?.name}</TableCell>
      <TableCell>
        {moment(ele?.createdAt)
          .tz("Asia/Kolkata")
          .format("YYYY-MM-DD HH:mm:ss")}
      </TableCell>
      <TableCell>
        {moment(ele?.updatedAt)
          .tz("Asia/Kolkata")
          .format("YYYY-MM-DD HH:mm:ss")}
      </TableCell>
      <TableCell>
        {isEditable && (
          <Stack direction="row" justifyContent="center">
            <PopUpdate id={ele.id} setRefresh={setRefresh} refresh={refresh} />
            <IconButton color="error" onClick={handleDelete}>
              <DeleteRoundedIcon />
            </IconButton>
          </Stack>
        )}
      </TableCell>
    </TableRow>
  );
};

export default HistoryTableRow;
