import { Box } from "@mui/material";
import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Navigate, useLocation } from "react-router-dom";
import useCommonDetails from "../hooks/useCommonDetails";
import { getCurrentAuth } from "../hooks/http-server";
import { APP_ROUTES } from "../utils/constants";

const PublicRoute = ({ children }: { children: React.ReactElement }) => {
  const isAuthenticated = getCurrentAuth();
  const userData = useCommonDetails();

  let location = useLocation();
  if (isAuthenticated.token) {
    return (
      <Navigate
        to={APP_ROUTES.Home}
        state={{ from: location.pathname, search: location.search }}
        replace
      />
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Header />
      <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
        {children}
      </Box>
      <Footer />
    </Box>
  );
};

export default PublicRoute;
