import { Alert, Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../utils/constants";

const ForgotPassword = () => {
  const navigate = useNavigate();
  return (
    <Box>
      <Alert severity="info" sx={{ mt: 2, width: "88%", mx: "auto" }}>
        For forgot password assistance please contact us via Telegram at{" "}
        <a href={process.env.REACT_APP_TELEGRAM_LINK}>
          <Typography component={"span"} color={"primary.main"}>
            +91 96241 25662
          </Typography>
        </a>
        .
      </Alert>
      <Box sx={{ mt: 4, display: "flex" }}>
        <Button
          variant="contained"
          sx={{ px: 5, mx: "auto" }}
          onClick={() => navigate(APP_ROUTES.Login)}
        >
          Back
        </Button>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
