import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import HttpService from "../../../hooks/Https-services";
import { API_ROUTES } from "../../../utils/constants";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";

const PopMenu = (props: any) => {
  const { type, id } = props;
  const [amount, setAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleAmount = () => {
    setOpen(true);
  };
  const hadleTransactions = async () => {
    try {
      const data = {
        userId: id,
        transferType: type,
        amount: amount,
      };
      setIsLoading(true);
      const res = await HttpService.post(API_ROUTES.AddTranscationAdmin, data);
      {
        type == 1
          ? toast.success("Amount Added successfully.")
          : toast.success("Amount Withdraw successfully.");
      }
      setOpen(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  return (
    <>
      <Button
        variant="contained"
        color={type == 1 ? "success" : "error"}
        onClick={handleAmount}
      >
        {type == 1 ? "Add" : "Withdraw"}
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Card sx={{ p: 1.5 }}>
          <CardContent>
            <Stack spacing={2}>
              {type == 1 ? (
                <Typography variant="h6">Enter Deposite Amount</Typography>
              ) : (
                <Typography variant="h6">Enter Withdraw Amount</Typography>
              )}
              <TextField
                variant="outlined"
                label="Amount"
                onChange={(e: any) => setAmount(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography variant="body1">Rs</Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
          </CardContent>
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <Stack spacing={1} direction="row">
              <Button variant="outlined" onClick={() => setOpen(false)}>
                Cancel
              </Button>
              <LoadingButton
                loading={isLoading}
                variant="contained"
                type="submit"
                onClick={hadleTransactions}
              >
                Confirm
              </LoadingButton>
            </Stack>
          </CardActions>
        </Card>
      </Dialog>
    </>
  );
};

export default PopMenu;
