import { Container } from "@mui/material";
import { Box } from "@mui/system";

const ContactUS = () => {
  return (
    <Container maxWidth={"lg"}>
      <h1>Contact Us</h1>
      <h4> Last updated on 09-12-2023 11:21:46</h4>
      <h5>You may contact us using the information below:</h5>
      Merchant Legal entity name: Lucifer Morningstar
      <br />
      Registered Address: 707 Dye Street, Phoenix - Arizona,
      <br />
      E-Mail ID: websparrow8@gmail.com
      <br />
      Contact Number: +91 96241 25662
    </Container>
  );
};
export default ContactUS;
