import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import coin from "../../assets/images/coin.png";
import bgCard from "../../assets/images/Widget.png";
import AddCardOutlinedIcon from "@mui/icons-material/AddCardOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import banner2 from "../../assets/images/banner2.png";
import { useLocation, useNavigate } from "react-router-dom";
import { API_ROUTES, APP_ROUTES } from "../../utils/constants";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import time from "../../assets/images/timeIcon.png";
import HttpService from "../../hooks/Https-services";
import useCommonDetails from "../../hooks/useCommonDetails";
import moment from "moment-timezone";
import image from "../../assets/images/image 2.png";
import winnerBg from "../../assets/images/winner.png";
import loserBg from "../../assets/images/loser.png";
import trophy from "../../assets/images/winner2.png";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";

const GameList = () => {
  const location = useLocation();
  const path = location.pathname;
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(true);
  const [ammount, setAmmount] = useState(0);
  const [selectedGame, setSelectedGame] = useState("-1");
  const [startTime, setStartTime] = useState<any>({ min: "--", sec: "--" });
  const [currentBid, setCurrentBid] = useState<any>(null);
  const [prevBid, setPrevBid] = useState<any>(null);
  const [prevWin, setPrevWin] = useState<any>(null);
  const [prevWin2, setPrevWin2] = useState<any>(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [gid, setGid] = useState(0);
  const ref = useRef<any>(null);
  const ref2 = useRef<any>(null);
  const handleChange = (event: any) => {
    const temp = event.target.value > 0 ? event.target.value : 0;
    setAmmount(temp);
  };
  const navigate = useNavigate();
  const commonData = useCommonDetails();
  const handleBid = async () => {
    try {
      setIsLoading(true);
      const data = {
        userId: commonData.id,
        amount: Number(ammount),
        gameId: gid,
      };
      const res = await HttpService.post(API_ROUTES.AddBid, data);
      setIsLoading(false);
      getBid();
      clearInterval(ref.current);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  const handleTime = (e: any) => {
    const endTime = e.add(process.env.REACT_APP_TIME, "minutes");
    ref.current = setInterval(() => {
      const remainingTime = moment.duration(
        endTime.diff(moment().tz("Asia/Kolkata"))
      );
      const minutes = remainingTime.minutes();
      const seconds = remainingTime.seconds();

      // console.log(`${minutes} minutes and ${seconds} seconds remaining`);
      if (minutes <= 0 && seconds <= 0) {
        setIsDisabled(true);
        clearInterval(ref.current);
        ref2.current = setTimeout(() => {
          toast.info("Game begins in a few seconds.");
          getBid();
        }, 5000);

        console.log("Timer expired");
        return;
        // Perform any action after the timer expires
      }
      if (isDisabled) {
        setIsDisabled(false);
      }
      const t = {
        min: minutes < 10 ? `0${minutes}` : minutes,
        sec: seconds < 10 ? `0${seconds}` : seconds,
      };
      setStartTime(t);
    }, 1000); // Call handleTime every 1 second
  };
  const handleSelect = (e: any, idx: any) => {
    setSelectedGame(idx);
    setGid(e);
  };
  const handleView = async () => {
    try {
      setIsLoading(true);
      const res = await HttpService.get(API_ROUTES.StartNewGame);
      setIsLoading(false);
      navigate(APP_ROUTES.Home);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  const getBid = async () => {
    try {
      const res = await HttpService.get(API_ROUTES.GetInterval);
      setCurrentBid(res.data.data.currentBidApplication);
      const time = res.data.data.timeInterval.startTime;
      const tp = moment(time);
      handleTime(tp.tz("Asia/Kolkata"));
      setPrevBid(res.data.data?.previousBidApplication);
      setPrevWin(res.data.data?.previousBidApplication?.previousWinner.game);
      setPrevWin2(res.data.data?.previousIntervalWinner.game);
      setIsLoading2(false);
    } catch (error) {
      setIsLoading2(false);
      console.log(error);
    }
  };
  const getGames = async () => {
    try {
      const data = {
        options: {
          page: 1,
          paginate: 3,
        },
      };
      const res = await HttpService.post(API_ROUTES.GetGames, data);
      const temp: any = [];
      res.data.data.data?.map((ele: any) => {
        temp.push({
          id: ele.id,
          name: ele.name,
          x: ele.compoundingValue,
          url: ele.icon[0].image,
        });
      });
      setData(temp);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getGames();
    getBid();
    return () => {
      clearInterval(ref.current);
      clearTimeout(ref2.current);
    };
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        // gap: 2.5,
        height: "100%",
      }}
    >
      {prevBid ? (
        <>
          {prevBid?.isViewed ? (
            <>
              {!isLoading2 ? (
                <Card
                  sx={{
                    borderRadius: "16px",
                    backgroundImage: `url(${bgCard}) `,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    mt: 2,
                  }}
                >
                  <CardContent sx={{ p: "20px !important" }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 1,
                      }}
                    >
                      <Box component="img" src={time} height={40} />
                      <Typography variant="h2" color="white" fontWeight="500">
                        {startTime.min} : {startTime.sec}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              ) : (
                <Skeleton
                  variant="rounded"
                  width={"100%"}
                  height={110}
                  sx={{ marginTop: 2 }}
                />
              )}
              {!currentBid ? (
                !isLoading2 ? (
                  <Box mt={2}>
                    <Typography variant="h6" fontWeight={600}>
                      Select an option to start your play
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        mt: 3,
                      }}
                    >
                      {data.map((ele: any, idx: any) => (
                        <>
                          <Card
                            sx={{
                              borderRadius: "48px",
                              border:
                                selectedGame == idx
                                  ? "2px solid #6b4eff"
                                  : "unset", // Change border when selected
                              color: selectedGame == idx ? "#6b4eff" : "black",
                              backgroundColor:
                                selectedGame == idx ? "#e7e7ff" : "white",
                              cursor: "pointer",
                            }}
                            onClick={() => handleSelect(ele.id, idx)}
                          >
                            <CardContent sx={{ p: "12px !important" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: 2,
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  mx: 1,
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 2,
                                  }}
                                >
                                  <Box
                                    component={"img"}
                                    src={ele.url}
                                    width={34}
                                  />
                                  <Typography variant="h6" fontWeight={600}>
                                    {ele.name}
                                  </Typography>
                                </Box>
                                <Typography
                                  variant="h6"
                                  fontWeight={500}
                                  color={"gray"}
                                >
                                  {ele.x} x
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                          {selectedGame == idx && (
                            <TextField
                              placeholder="Enter Amount (Rs.)"
                              type="number"
                              fullWidth
                              size="small"
                              value={ammount ? ammount : ""}
                              onChange={handleChange}
                              InputProps={{
                                sx: {
                                  textAlign: "center",
                                  borderRadius: "8px",
                                  fontWeight: "500",
                                },
                              }}
                              inputProps={{
                                sx: {
                                  textAlign: "center",
                                },
                              }}
                              sx={{
                                borderRadius: "8px",
                                mt: -0.5,
                                width: "98%",
                                alignSelf: "center",
                                textAlign: "center",
                                "& input::placeholder": {
                                  textAlign: "center",
                                },

                                backgroundColor: "white", // Set your desired background color here
                              }}
                            />
                          )}
                        </>
                      ))}
                    </Box>
                    <LoadingButton
                      loading={isLoading}
                      variant="contained"
                      size="large"
                      fullWidth
                      sx={{ py: "12px", mt: 3 }}
                      disabled={!ammount || isDisabled}
                      onClick={handleBid}
                    >
                      Continue
                    </LoadingButton>
                    <Button
                      variant="outlined"
                      sx={{ color: "black", mt: 4 }}
                      fullWidth
                      size="small"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          my: 0.5,
                          gap: 1,
                        }}
                      >
                        <Box component="img" src={trophy} width={30} />
                        <Typography>
                          The last round winner was{" "}
                          <Typography color={"primary.main"} component="span">
                            {prevWin2?.name}{" "}
                          </Typography>
                        </Typography>
                        <Box
                          component="img"
                          src={prevWin2?.icon[0].image}
                          width={30}
                        />
                      </Box>
                    </Button>
                  </Box>
                ) : (
                  <Skeleton
                    variant="rounded"
                    width={"100%"}
                    height={400}
                    sx={{ marginTop: 2 }}
                  />
                )
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    flexGrow: 1,
                  }}
                >
                  <Box>
                    <Box
                      component="img"
                      src={currentBid?.game?.icon[0].image}
                      width={64}
                      height={64}
                    />
                    <Typography variant="h6" fontWeight={600} mt={1}>
                      Bid amount is Rs. {currentBid?.amount}
                    </Typography>
                    <Typography
                      variant="body1"
                      color="gray"
                      fontWeight={400}
                      mt={1}
                    >
                      The results will be declared shortly...
                    </Typography>
                  </Box>
                </Box>
              )}
            </>
          ) : prevBid?.result ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                flexGrow: 1,
                gap: 1.5,
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 600,
                  background:
                    "linear-gradient(92deg, #0056AB 0%, #0874C5 100%)",
                  backgroundClip: "text",
                  "-webkit-background-clip": "text",
                  "-webkit-text-fill-color": "transparent",
                }}
              >
                Congratulations!
              </Typography>
              <Typography variant="h6" color="gray" fontWeight="400">
                You won Rs. {prevBid.lastWinningAmount}
              </Typography>
              <Box component="img" src={winnerBg} width={"100%"} />
              <LoadingButton
                loading={isLoading}
                variant="contained"
                fullWidth
                onClick={handleView}
              >
                Get Start New Bid
              </LoadingButton>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                flexGrow: 1,
                gap: 2,
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 600,
                  background:
                    "linear-gradient(92deg, #0056AB 0%, #0874C5 100%)",
                  backgroundClip: "text",
                  "-webkit-background-clip": "text",
                  "-webkit-text-fill-color": "transparent",
                }}
              >
                You Lose!
              </Typography>
              <Typography variant="h6" color="gray" fontWeight="400">
                Please try again in your next attempt.
              </Typography>

              <Box component="img" src={loserBg} width={230} />
              <Button variant="outlined" sx={{ color: "black" }} size="small">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    my: 1,
                    gap: 1,
                  }}
                >
                  <Box
                    component="img"
                    src={prevWin?.icon[0].image}
                    width={30}
                  />
                  <Typography>
                    <Typography color={"primary.main"} component="span">
                      {prevWin?.name}{" "}
                    </Typography>
                    League Won.
                  </Typography>
                  <Box component="img" src={trophy} width={30} />
                </Box>
              </Button>
              <LoadingButton
                loading={isLoading}
                variant="contained"
                fullWidth
                onClick={handleView}
              >
                Start New Bid
              </LoadingButton>
            </Box>
          )}
        </>
      ) : (
        <>
          {!isLoading2 ? (
            <Card
              sx={{
                borderRadius: "16px",
                backgroundImage: `url(${bgCard}) `,
                backgroundSize: "cover",
                backgroundPosition: "center",
                mt: 2,
              }}
            >
              <CardContent sx={{ p: "20px !important" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 1,
                  }}
                >
                  <Box component="img" src={time} height={40} />
                  <Typography variant="h2" color="white" fontWeight="500">
                    {startTime.min} : {startTime.sec}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          ) : (
            <Skeleton
              variant="rounded"
              width={"100%"}
              height={110}
              sx={{ marginTop: 2 }}
            />
          )}
          {!currentBid ? (
            !isLoading2 ? (
              <Box mt={2}>
                <Typography variant="h6" fontWeight={600}>
                  Select an option to start your play
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    mt: 3,
                  }}
                >
                  {data.map((ele: any, idx: any) => (
                    <>
                      <Card
                        sx={{
                          borderRadius: "48px",
                          border:
                            selectedGame == idx ? "2px solid #6b4eff" : "unset", // Change border when selected
                          color: selectedGame == idx ? "#6b4eff" : "black",
                          backgroundColor:
                            selectedGame == idx ? "#e7e7ff" : "white",
                          cursor: "pointer",
                        }}
                        onClick={() => handleSelect(ele.id, idx)}
                      >
                        <CardContent sx={{ p: "12px !important" }}>
                          <Box
                            sx={{
                              display: "flex",
                              gap: 2,
                              alignItems: "center",
                              justifyContent: "space-between",
                              mx: 1,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 2,
                              }}
                            >
                              <Box component={"img"} src={ele.url} width={34} />
                              <Typography variant="h6" fontWeight={600}>
                                {ele.name}
                              </Typography>
                            </Box>
                            <Typography
                              variant="h6"
                              fontWeight={500}
                              color={"gray"}
                            >
                              {ele.x} x
                            </Typography>
                          </Box>
                        </CardContent>
                      </Card>
                      {selectedGame == idx && (
                        <TextField
                          placeholder="Enter Amount (Rs.)"
                          type="number"
                          fullWidth
                          size="small"
                          value={ammount ? ammount : ""}
                          onChange={handleChange}
                          InputProps={{
                            sx: {
                              textAlign: "center",
                              borderRadius: "8px",
                              fontWeight: "500",
                            },
                          }}
                          inputProps={{
                            sx: {
                              textAlign: "center",
                            },
                          }}
                          sx={{
                            borderRadius: "8px",
                            mt: -0.5,
                            width: "98%",
                            alignSelf: "center",
                            textAlign: "center",
                            "& input::placeholder": {
                              textAlign: "center",
                            },

                            backgroundColor: "white", // Set your desired background color here
                          }}
                        />
                      )}
                    </>
                  ))}
                </Box>
                <LoadingButton
                  loading={isLoading}
                  variant="contained"
                  size="large"
                  fullWidth
                  sx={{ py: "12px", mt: 3 }}
                  disabled={!ammount || isDisabled}
                  onClick={handleBid}
                >
                  Continue
                </LoadingButton>
                <Button
                  variant="outlined"
                  sx={{ color: "black", mt: 4 }}
                  fullWidth
                  size="small"
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      my: 0.5,
                      gap: 1,
                    }}
                  >
                    <Box component="img" src={trophy} width={30} />
                    <Typography>
                      The last round winner was{" "}
                      <Typography color={"primary.main"} component="span">
                        {prevWin2?.name}{" "}
                      </Typography>
                    </Typography>
                    <Box
                      component="img"
                      src={prevWin2?.icon[0].image}
                      width={30}
                    />
                  </Box>
                </Button>
              </Box>
            ) : (
              <Skeleton
                variant="rounded"
                width={"100%"}
                height={400}
                sx={{ marginTop: 2 }}
              />
            )
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                flexGrow: 1,
              }}
            >
              <Box>
                <Box
                  component="img"
                  src={currentBid?.game?.icon[0].image}
                  width={64}
                  height={64}
                />
                <Typography variant="h6" fontWeight={600} mt={1}>
                  Bid amount is Rs. {currentBid?.amount}
                </Typography>
                <Typography
                  variant="body1"
                  color="gray"
                  fontWeight={400}
                  mt={1}
                >
                  The results will be declared shortly...
                </Typography>
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default GameList;
