import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import banner1 from "../../assets/images/Hero-Img.png";
import banner2 from "../../assets/images/banner2.png";
import banner3 from "../../assets/images/banner3.png";
import banner4 from "../../assets/images/logo_with_text.png";
import { text } from "stream/consumers";
import { useNavigate } from "react-router";
import { APP_ROUTES } from "../../utils/constants";

const LandingPage = () => {
  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down("sm")
  );
  const navigate = useNavigate();
  return (
    <Box>
      <Box sx={{ backgroundColor: "#e7e7ff" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            py: 5,
            mx: 2,
          }}
        >
          <Typography
            variant="h3"
            fontWeight={600}
            sx={{
              textAlign: "center",
              fontSize: { xs: "1.6rem", sm: "2rem", md: "3rem" },
            }}
          >
            Welcome to{" "}
            <Typography
              component="span"
              color="#6b4eff"
              fontWeight="600"
              sx={{
                fontSize: { xs: "1.6rem", sm: "2rem", md: "3rem" },
              }}
            >
              SparrowWeb{""}
            </Typography>
            , the <br /> fantasy game of your dreams!
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
            justifyContent: "center",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Button
            variant="contained"
            size={isSmallScreen ? "medium" : "large"}
            onClick={() => navigate(APP_ROUTES.Register)}
          >
            Create Account
          </Button>
          <Button
            variant="text"
            size={isSmallScreen ? "medium" : "large"}
            onClick={() => navigate(APP_ROUTES.Login)}
          >
            Already have an account?
          </Button>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box
            component="img"
            src={banner1}
            maxHeight={"70vh"}
            width={{ xs: "80%", lg: "unset" }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: { xs: "column-reverse", sm: "row" },
          justifyContent: "space-evenly",
          py: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            alignItems: { xs: "center", sm: "start" },
            m: 2,
            textAlign: { xs: "center", sm: "left" },
          }}
        >
          <Typography
            variant="h4"
            fontWeight={600}
            sx={{ fontSize: { xs: "1.4rem", sm: "1.6rem", md: "2.125rem" } }}
          >
            About Us
          </Typography>
          <Typography
            color="gray"
            sx={{ fontSize: { xs: "14px", sm: "16px" } }}
          >
            With stunning graphics and endless gameplay possibilities,
            SparrowWeb <br /> is the ultimate fantasy game experience.
          </Typography>
          <Button
            variant="contained"
            size={isSmallScreen ? "medium" : "large"}
            onClick={() => navigate(APP_ROUTES.Login)}
          >
            Play Game
          </Button>
        </Box>
        <Box>
          <Box
            component="img"
            src={banner2}
            maxHeight={{ xs: 200, sm: 300 }}
            width={{ xs: "100%", lg: "unset" }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          backgroundColor: "#e7e7ff",
          justifyContent: "space-evenly",
          flexDirection: { xs: "column-reverse", sm: "row" },
          alignItems: "center",
          textAlign: { xs: "center", sm: "left" },
          py: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            alignItems: { xs: "center", sm: "start" },
            m: 2,
          }}
        >
          <Box
            component="img"
            src={banner4}
            width={{ xs: "30%", lg: "unset" }}
          />
          <Typography
            variant="h4"
            fontWeight={600}
            sx={{ fontSize: { xs: "1.4rem", sm: "1.6rem", md: "2.125rem" } }}
          >
            What are you waiting for?
          </Typography>
          <Typography
            color="gray"
            sx={{ fontSize: { xs: "14px", sm: "16px" } }}
          >
            With stunning graphics and endless gameplay possibilities,
            SparrowWeb <br /> is the ultimate fantasy game experience.
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "center",
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <Button
              variant="contained"
              size={isSmallScreen ? "medium" : "large"}
              onClick={() => navigate(APP_ROUTES.Register)}
            >
              Create Account
            </Button>
            <Button
              variant="text"
              size={isSmallScreen ? "medium" : "large"}
              onClick={() => navigate(APP_ROUTES.Login)}
            >
              Already have an account?
            </Button>
          </Box>
        </Box>
        <Box>
          <Box
            component="img"
            src={banner3}
            maxHeight={520}
            width={{ xs: "90%", lg: "unset" }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          py: 4,
          mx: 2,
          alignItems: "center",
          textAlign: { xs: "center", md: "left" },
        }}
      >
        <Typography
          variant="h4"
          fontWeight={600}
          sx={{ fontSize: { xs: "1.4rem", sm: "1.6rem", md: "2.125rem" } }}
        >
          Explore a vast and open world
        </Typography>
        <Typography
          variant="h6"
          fontWeight={500}
          color={"gray"}
          sx={{ fontSize: { xs: "16px", md: "20px" } }}
        >
          Discover hidden secrets and uncover ancient mysteries as you journey
          across the land.
        </Typography>
        <Grid
          container
          spacing={{ xs: 1.5, sm: 2 }}
          maxWidth={{ xs: "100%", sm: "82%", md: "850px" }}
          sx={{ textAlign: "left" }}
        >
          <Grid item xs={6} md={3}>
            <Box
              sx={{
                backgroundColor: "#E7E7FF",
                p: 4,
                flex: 1,
                borderRadius: "6px",
              }}
            >
              <Typography
                variant="h6"
                fontWeight={700}
                noWrap
                sx={{ fontSize: { xs: "16px", md: "20px" } }}
              >
                1<br />
                Sign Up
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} md={3}>
            <Box
              sx={{
                backgroundColor: "#E7E7FF",
                p: 4,
                borderRadius: "6px",
              }}
            >
              <Typography
                variant="h6"
                fontWeight={700}
                noWrap
                sx={{ fontSize: { xs: "16px", md: "20px" } }}
              >
                2<br />
                Add Money
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} md={3}>
            <Box
              sx={{
                backgroundColor: "#E7E7FF",
                p: 4,
                borderRadius: "6px",
              }}
            >
              <Typography
                variant="h6"
                fontWeight={700}
                noWrap
                sx={{ fontSize: { xs: "16px", md: "20px" } }}
              >
                3<br />
                Bid Amount
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} md={3}>
            <Box
              sx={{
                backgroundColor: "#E7E7FF",
                p: 4,
                borderRadius: "6px",
              }}
            >
              <Typography
                variant="h6"
                fontWeight={700}
                noWrap
                sx={{ fontSize: { xs: "16px", md: "20px" } }}
              >
                4<br />
                Get Result
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          size={isSmallScreen ? "medium" : "large"}
          sx={{ width: { xs: "82%", md: "850px" } }}
          onClick={() => navigate(APP_ROUTES.Login)}
        >
          Play SparrowWeb today!
        </Button>
      </Box>
    </Box>
  );
};

export default LandingPage;
