import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import coin from "../../assets/images/coin.png";
import bgCard from "../../assets/images/Widget.png";
import AddCardOutlinedIcon from "@mui/icons-material/AddCardOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import banner2 from "../../assets/images/banner2.png";
import { API_ROUTES, APP_ROUTES } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import HttpService from "../../hooks/Https-services";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { debounce } from "lodash";

const HomePage = () => {
  const [balance, setBalance] = useState(0);
  const [ammount, setAmmount] = useState(0);
  const [isloading, setLoading] = useState(true);
  const [isAdd, setIsAdd] = useState(true);
  const ref = useRef<any>(null);
  const handleChange = (event: any) => {
    const temp = event.target.value > 0 ? event.target.value : 0;
    setAmmount(temp);
  };
  const amountEnv: any = Number(process.env.REACT_APP_AMOUNT) || 0;
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const getWallet = async () => {
    try {
      const res = await HttpService.get(API_ROUTES.GetWallet);
      setBalance(res.data.data.balance);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    // Load Cashfree SDK
    const script = document.createElement("script");
    script.src = "https://sdk.cashfree.com/js/v3/cashfree.js";
    script.async = true;
    document.head.appendChild(script);
  }, []);
  // const GetPayment = (session: any) => {
  //   let checkoutOptions = {
  //     paymentSessionId: session,
  //     returnUrl: "http://localhost:3000/home",
  //   };
  //   cashfree.checkout(checkoutOptions).then(function (result) {
  //     if (result.error) {
  //       window.location.href = "http://localhost:3000/home";
  //     }
  //     if (result.redirect) {
  //       console.log("Redirection");
  //       console.log(result);
  //     }
  //   });
  // };
  const handlePayment = async () => {
    try {
      if (watch("amount") < 1) {
        return;
      }
      const dataBody = {
        transferType: isAdd ? 1 : 2,
        amount: watch("amount"),
      };

      const res = await HttpService.post(API_ROUTES.AddTranscation, dataBody);
      if (!isAdd) {
        getWallet();
      }
      reset();
      setValue("amount", 0);
      setIsAdd(true);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getWallet();
  }, []);
  const handleAmountChange = () => {
    // Perform debounced action after 2 seconds when typing stops
    clearTimeout(ref.current);
    ref.current = setTimeout(() => {
      // Call your getWallet function here
      if (!isAdd) {
        getWallet();
      }
    }, 500);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2.5,
        height: "100%",
      }}
    >
      <Card
        sx={{
          borderRadius: "16px",
          backgroundImage: `url(${bgCard}) `,
          backgroundSize: "cover",
          backgroundPosition: "center",
          mt: 2,
          cursor: "pointer",
        }}
        onClick={() => navigate(APP_ROUTES.History)}
      >
        <CardContent sx={{ p: "20px !important" }}>
          <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
            <Box>
              <Box component={"img"} src={coin} height={55} />
            </Box>

            <Box>
              {!isloading ? (
                <Typography variant="h6" fontWeight={600} color={"white"}>
                  Rs. {balance}
                </Typography>
              ) : (
                <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
              )}
              <Typography variant="body1" color={"white"}>
                Wallet balance
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
      <Box sx={{ display: "flex", gap: 2 }}>
        <Button
          variant="contained"
          color={isAdd ? "primary" : "secondary"}
          size="large"
          fullWidth
          sx={{ py: 2 }}
          onClick={() => setIsAdd(true)}
          startIcon={
            <AddCardOutlinedIcon
              sx={{
                color: isAdd ? "#fff" : "#6b4eff",
                fontSize: "30px !important",
              }}
            />
          }
        >
          Add Money
        </Button>
        <Button
          variant="contained"
          color={!isAdd ? "primary" : "secondary"}
          size="large"
          fullWidth
          sx={{ py: 2 }}
          onClick={() => setIsAdd(false)}
          startIcon={
            <FileUploadOutlinedIcon
              sx={{
                color: !isAdd ? "#fff" : "#6b4eff",
                fontSize: "30px !important",
              }}
            />
          }
        >
          Withdraw
        </Button>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <TextField
          placeholder="Enter Amount (Rs.)"
          type="number"
          fullWidth
          {...register("amount", {
            onChange: () => {
              handleAmountChange();
            },
            required: "This field is required.",
          })}
          onKeyDown={(e: any) => {
            if (e.keyCode == 190) {
              e.preventDefault();
            }
          }}
          InputProps={{
            sx: {
              textAlign: "center",
              borderRadius: "8px",
              fontWeight: "600",
            },
          }}
          inputProps={{
            sx: {
              textAlign: "center",
            },
          }}
          sx={{
            borderRadius: "8px",
            mt: -0.5,
            width: "98%",
            alignSelf: "center",
            textAlign: "center",
            "& input::placeholder": {
              textAlign: "center",
            },
            backgroundColor: "white", // Set your desired background color here
          }}
          error={!!errors.amount}
          helperText={
            errors.amount && (
              <Typography variant="caption" sx={{ color: "red" }}>
                {errors.amount.message as any}
              </Typography>
            )
          }
        />
        {isAdd ? (
          <Alert severity="info" sx={{ mt: 1.5, width: "88%", mx: "auto" }}>
            Minimum deposit amount is{" "}
            <Typography component={"span"} color={"primary.main"}>
              {process.env.REACT_APP_AMOUNT}
            </Typography>{" "}
            Rs.
          </Alert>
        ) : (
          <Alert severity="info" sx={{ mt: 1.5, width: "88%", mx: "auto" }}>
            Minimum require amount for wallet is{" "}
            <Typography component={"span"} color={"primary.main"}>
              {process.env.REACT_APP_AMOUNT}
            </Typography>{" "}
            Rs.
          </Alert>
        )}
        <Alert severity="info" sx={{ my: 1, width: "88%", mx: "auto" }}>
          For assistance with credit or withdrawal balance issues, please
          contact us via Telegram at{" "}
          <a href={process.env.REACT_APP_TELEGRAM_LINK}>
            <Typography component={"span"} color={"primary.main"}>
              +91 96241 25662
            </Typography>
          </a>
          .
        </Alert>

        <Button
          href={
            !isAdd
              ? `${process.env.REACT_APP_TELEGRAM_LINK}`
              : `${process.env.REACT_APP_TELEGRAM_LINK}`
          }
          variant="contained"
          size="large"
          fullWidth
          disabled={
            isAdd
              ? watch("amount") < amountEnv
              : balance - watch("amount") < amountEnv || !(watch("amount") > 0)
          }
          onClick={handlePayment}
          sx={{ mt: 2.5, py: 1.5 }}
        >
          {isAdd ? "Add Money" : "Withdraw Money"}
        </Button>
      </Box>

      <Button
        variant="contained"
        size="large"
        fullWidth
        color="info"
        sx={{ py: 2 }}
        onClick={() => navigate(APP_ROUTES.GameList)}
      >
        Start Game
      </Button>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 1,
        }}
      >
        <Box component="img" src={banner2} width={"100%"} />
      </Box>
    </Box>
  );
};

export default HomePage;
