import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../utils/constants";
import logo from "../../assets/images/logo.png";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import { useLocation } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import useCommonDetails from "../../hooks/useCommonDetails";
import { toast } from "react-toastify";

export const PrivateHeader = () => {
  const location = useLocation();
  const path = location.pathname;
  const navigate = useNavigate();
  const userDetail = useCommonDetails();
  const handleLogout = () => {
    localStorage.removeItem("auth");
    toast.success("Logout Successfully");
    navigate(APP_ROUTES.Landing);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "white",
        height: "46px",
        position: "sticky",
        top: 0,
        zIndex: 999,
        px: { xs: 2, sm: 4, md: 10 },
        borderBottom: "1px solid lightgray",
      }}
    >
      {path == APP_ROUTES.Home ? (
        <Box>
          <Box
            component={"img"}
            src={logo}
            height={42}
            sx={{ cursor: "pointer" }}
            onClick={() => navigate(APP_ROUTES.Landing)}
          />
        </Box>
      ) : (
        <ChevronLeftIcon
          onClick={() => navigate(-1)}
          sx={{
            cursor: "pointer",
            visibility: path == APP_ROUTES.Admin ? "hidden" : "unset",
          }}
        />
      )}
      <Typography variant="h6" fontWeight={600}>
        👋Hello, {userDetail.name}
      </Typography>
      {userDetail.userType != 2 ? (
        <FormatAlignRightIcon
          sx={{
            color: "#6b4eff",
            fontWeight: "600",
            visibility: location.pathname.includes(APP_ROUTES.SettingPage)
              ? "hidden"
              : "visible",
          }}
          onClick={() => navigate(APP_ROUTES.SettingPage)}
        />
      ) : (
        <Button variant="contained" color="error" onClick={handleLogout}>
          Log out
        </Button>
      )}
    </Box>
  );
};
