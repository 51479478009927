import React from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../utils/constants";

import useCommonDetails from "../hooks/useCommonDetails";
import MainContainer from "../components/MainContainer";
import Header from "../components/Header";
import { getCurrentAuth } from "../hooks/http-server";

const PrivateRoute = ({ children }: { children: React.ReactElement }) => {
  const isAuthenticated = getCurrentAuth();
  const userData = useCommonDetails();
  const navigate = useNavigate();
  let location = useLocation();
  if (!isAuthenticated.token) {
    return (
      <Navigate
        to={APP_ROUTES.Landing}
        state={{ from: location.pathname, search: location.search }}
        replace
      />
    );
  }
  if (userData.userType != 2 && location.pathname.includes(APP_ROUTES.Admin)) {
    return (
      <Navigate
        to={APP_ROUTES.Home}
        state={{ from: location.pathname, search: location.search }}
        replace
      />
    );
  }
  if (
    userData.userType == 2 &&
    location.pathname != APP_ROUTES.Admin &&
    location.pathname != APP_ROUTES.AdminRequest
  ) {
    return (
      <Navigate
        to={APP_ROUTES.Admin}
        state={{ from: location.pathname, search: location.search }}
        replace
      />
    );
  }

  return (
    <>
      <MainContainer>
        <>{children} </>
      </MainContainer>
    </>
  );
};

export default PrivateRoute;
