const Style = {
  PrivacyParaStyle: { lineHeight: "30px", textAlign: "justify" },
  PrivacyHeading: {
    fontSize: { xs: "26px", md: "40px" },
    fontWeight: 500,
    mt: 2,
  },
  PrivacyBox: { display: "flex", flexDirection: "column", gap: "1rem" },
  ListItemStyle: {
    listStyleType: "disc",
    padding: 0,
    display: "list-item",
    my: 1,
  },
};
export default Style;
