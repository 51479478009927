import { Box, Typography } from "@mui/material";
import React from "react";
import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../utils/constants";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        borderTop: "1px solid lightgray",
        textAlign: "center",
        px: { xs: 2, sm: 4, md: 10 },
      }}
    >
      <Box>
        <Box
          component={"img"}
          src={logo}
          width={{ xs: 60, sm: 80 }}
          sx={{ cursor: "pointer" }}
          onClick={() => navigate(APP_ROUTES.Landing)}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          alignItems: "center",
        }}
      >
        <Typography
          variant="body1"
          color={"gray"}
          sx={{ cursor: "pointer" }}
          fontSize={{
            xs: 12,
            sm: 16,
          }}
          onClick={() => navigate(APP_ROUTES.Term)}
        >
          Terms & Conditions
        </Typography>
        <Typography
          variant="body1"
          color={"gray"}
          sx={{ cursor: "pointer" }}
          fontSize={{
            xs: 12,
            sm: 16,
          }}
          onClick={() => navigate(APP_ROUTES.Privacy)}
        >
          Privacy Policy
        </Typography>
        <Typography
          variant="body1"
          color={"gray"}
          sx={{ cursor: "pointer" }}
          fontSize={{
            xs: 12,
            sm: 16,
          }}
          onClick={() => navigate(APP_ROUTES.ContactUs)}
        >
          Contact Us
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
